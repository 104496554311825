<template>
  <c-step
    stepperGrpCd="IIM_ACCIDENT_STEP_CD"
    :currentStepCd="currentStepCd"
    :param="popupParam"
    :setSizeAgain="setSize"
    v-model="stepCd"
    @currentStep="currentStep"
    @setReportTab="setReportTab"
    @closePopup="closePopup"
    @stepClick="stepClick"
   >
  </c-step>
</template>

<script>
export default {
  name: 'process-accident-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        iimAccidentId: '',
        stepCd: '',
      }),
    },
    setSize: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      editable: true,
      stepCd: '',
      currentStepCd: '',
    };
  },
  watch: {
  },
  computed: {
    param() {
      return {
        ramRiskAssessmentPlanId: this.popupParam.ramRiskAssessmentPlanId,
        ramStepCd: this.popupParam.ramStepCd,
        vendorFlag: this.popupParam.vendorFlag,
        ramTechniqueCd: this.popupParam.ramTechniqueCd,
      }
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
      this.setStep();
    },
    setStep() {
      if (!this.popupParam.stepCd || this.popupParam.stepCd === 'ISPC000001') {
        this.stepCd = 'IASC000001'
        this.currentStepCd = 'IASC000001'
      } else if (this.popupParam.stepCd === 'ISPC000002') {
        this.stepCd = 'IASC000005'
        this.currentStepCd = 'IASC000010'
      } else if (this.popupParam.stepCd === 'ISPC000003') {
        this.stepCd = 'IASC000005'
        this.currentStepCd = 'IASC000010'
      } else if (this.popupParam.stepCd === 'ISPC000004') {
        this.stepCd = 'IASC000010'
        this.currentStepCd = 'IASC000010'
      } else {
        this.stepCd = 'IASC000015'
        this.currentStepCd = 'IASC000015'
      }
    },
    currentStep(step) {
      if (!step || step === 'ISPC000001') {
        this.stepCd = 'IASC000001'
        this.currentStepCd = 'IASC000001'
      } else if (step === 'ISPC000002') {
        this.stepCd = 'IASC000005'
        this.currentStepCd = 'IASC000010'
      } else if (step === 'ISPC000003') {
        this.stepCd = 'IASC000005'
        this.currentStepCd = 'IASC000010'
      } else if (step === 'ISPC000004') {
        this.stepCd = 'IASC000010'
        this.currentStepCd = 'IASC000010'
      } else {
        this.stepCd = 'IASC000015'
        this.currentStepCd = 'IASC000015'
      }
      this.popupParam.stepCd = step;
    },
    closePopup() {
      this.$emit('closePopup')
    },
    stepClick() {
      this.popupParam.count++;
    },
    setReportTab(data) {
      this.$emit('setReportTab', data)
    },  
  }
};
</script>
